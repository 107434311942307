import { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import Chart from 'react-apexcharts';

import get from 'lodash.get';
import defaultsDeep from 'lodash.defaultsdeep';

import defaultOptions from './default_radar_options';

const PilingByZoneSummaryRadarChart = ({ pageData, TableBuildStatuses }) => {
  const chartData = useMemo(() => {
    if (pageData?.tableSummaryByZoneReport) {
      const tableData = get(pageData, 'tableSummaryByZoneReport.report.data');
      const contractorCompletes = Object.values(tableData).map((zoneData) => {
        const contractorCompleteRow = zoneData.rows.find(
          (d) => d.status === TableBuildStatuses.CONTRACTOR_PILING_CHECKLIST_COMPLETE
        );
        return contractorCompleteRow.accumPercent;
      });
      const inspectorCompletes = Object.values(tableData).map((zoneData) => {
        const inspectorCompleteRow = zoneData.rows.find(
          (d) => d.status === TableBuildStatuses.INSPECTOR_PILING_CHECKLIST_COMPLETE
        );
        return inspectorCompleteRow.accumPercent;
      });
      const newChartData = {
        series: [
          {
            name: 'Checklist complete',
            data: contractorCompletes,
          },
          {
            name: 'Inspector accepted',
            data: inspectorCompletes,
          },
        ],
        categories: Object.keys(tableData).map((zone) => `Zone ${zone}`),
      };
      return newChartData;
    }
    return { series: [], categories: [] };
  }, [pageData, TableBuildStatuses]);

  // const series = Object.values(chartData);
  const { series, categories } = chartData;
  const options = defaultsDeep(
    {
      xaxis: {
        categories,
      },
      title: { text: 'Piling By Zone Summary' },
    },
    defaultOptions
  );

  return (
    <Card>
      <Card.Body>
        <Chart options={options} series={series} type="radar" height="350" />
      </Card.Body>
    </Card>
  );
};

export default PilingByZoneSummaryRadarChart;
