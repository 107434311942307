import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash.get';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';

const unhydratedInitialState = {
  trackedMutationCount: 0,
  tenant: '',
  tenantLongName: '',
  tenantShortName: '',
  versionCurrent: true,
  mutating: false,
  online: false,
  deviceOnline: false,
  serverOnline: false,
  bootswatchStyle: 'sandstone',
};

const initialState = {
  ...unhydratedInitialState,
  isOpenSidebar: true,
  defaultHomeRoute: '/home',
  devannerHomeRoute: '/goods_receipt',
  siteMapTypeaheadTableId: '',
  siteMapFilterBuildStatus: '',
  siteMapFilterInverterId: '',
  siteMapFilterCombinerBoxName: '',
  siteMapDefaultCenterLng: 175.1324030750164,
  siteMapDefaultCenterLat: -37.44031462619129,
  siteMapCenterLng: 175.1324030750164,
  siteMapCenterLat: -37.44031462619129,
  siteMapDefaultZoom: 16,
  siteMapZoom: 16,
  tableState: {
    consignment: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [],
      columnFilters: [],
    },
    consignmentShowConsignmentItem: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'consignmentItemReference', desc: false }],
      columnFilters: [],
    },
    consignmentItem: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'consignmentItemReference', desc: false }],
      columnFilters: [],
    },
    consignmentImage: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'consignment', desc: false }],
      columnFilters: [],
    },
    consignmentItemSummaryReport: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'supplierName', desc: false }],
      columnFilters: [],
    },
    consignmentItemByDescriptionSummaryReport: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'description', desc: false }],
      columnFilters: [],
    },
    consignmentItemBySiteLocationSummaryReport: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'consignmentItemDescription', desc: false }],
      columnFilters: [],
    },
    consignmentItemsDuplicateReceiptsReport: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'consignmentItemReference', desc: false }],
      columnFilters: [],
    },
    consignmentItemsZeroReceiptsReport: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'consignmentItemReference', desc: false }],
      columnFilters: [],
    },
    inverter: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'name', desc: false }],
      columnFilters: [],
    },
    picklist: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'toSiteLocation', desc: false }],
      columnFilters: [],
    },
    picklistShowPicklistSupplierCatalogItem: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'description', desc: false }],
      columnFilters: [],
    },
    pile: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'name', desc: false }],
      columnFilters: [],
    },
    pilingMachine: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'name', desc: false }],
      columnFilters: [],
    },
    pileByDateByPileMachineReport: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'finishedOn', desc: true }],
      columnFilters: [],
    },
    pilesMissingTable: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'name', desc: false }],
      columnFilters: [],
    },
    tablesMissingPiles: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'name', desc: false }],
      columnFilters: [],
    },
    receipt: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'updatedAt', desc: true }],
      columnFilters: [],
    },
    supplier: {
      pagination: { pageIndex: 0, pageSize: 50 },
      sorting: [{ id: 'name', desc: false }],
      columnFilters: [],
    },
    table: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'name', desc: false }],
      columnFilters: [],
    },
    manageTable: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'name', desc: false }],
      columnFilters: [],
    },
    user: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [],
      columnFilters: [],
    },
    visitorLog: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'reportOn', desc: true }],
      columnFilters: [],
    },
    wbsCode: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [
        { id: 'prefix', desc: false },
        { id: 'categoryCode', desc: false },
        { id: 'taskCode', desc: false },
        { id: 'activityCode', desc: false },
      ],
      columnFilters: [],
    },
  },
  listColumnFilters: {
    consignmentImage: [],
    manufacturer: [],
    picklistProduct: [],
    picklistSupplierCatalogItem: [],
    picklistTemplate: [],
    picklistTemplateProduct: [],
    product: [],
    productCategory: [],
    purchaser: [],
    productProduct: [],
    productSupplierCatalogItem: [],
    site: [],
    sitelocation: [],
    supplierCatalog: [],
    supplierCatalogItem: [],
  },
  listPagination: {
    consignmentImage: { pageIndex: 0, pageSize: 50 },
    manufacturer: { pageIndex: 0, pageSize: 50 },
    picklistProduct: { pageIndex: 0, pageSize: 50 },
    picklistSupplierCatalogItem: { pageIndex: 0, pageSize: 50 },
    picklistTemplate: { pageIndex: 0, pageSize: 50 },
    picklistTemplateProduct: { pageIndex: 0, pageSize: 50 },
    product: { pageIndex: 0, pageSize: 50 },
    productCategory: { pageIndex: 0, pageSize: 50 },
    purchaser: { pageIndex: 0, pageSize: 50 },
    productProduct: { pageIndex: 0, pageSize: 50 },
    productSupplierCatalogItem: { pageIndex: 0, pageSize: 50 },
    site: { pageIndex: 0, pageSize: 50 },
    siteLocation: { pageIndex: 0, pageSize: 50 },
    supplierCatalog: { pageIndex: 0, pageSize: 50 },
    supplierCatalogItem: { pageIndex: 0, pageSize: 50 },
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    settingsSet(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    settingsReset(state, action) {
      const initialStateKeys = action.payload;
      const initialStateData = get(initialState, initialStateKeys);
      if (initialStateData) {
        const newState = cloneDeep(state);
        set(newState, initialStateKeys, initialStateData);
        return newState;
      }
      return {
        ...state,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('persist/REHYDRATE', (_, action) => {
        const persistedState = get(action, ['payload', 'settings'], {});
        const persistedTableStage = get(persistedState, 'tableState', {});
        // TODO remove all this stuff once all resources in tableState
        const persistedListColumnFilters = get(persistedState, 'listColumnFilters', {});
        const persistedListPagination = get(persistedState, 'listPagination', {});
        return {
          ...initialState,
          ...persistedState,
          // stop rehydrate overwriting new table states
          tableState: {
            ...initialState.tableState,
            ...persistedTableStage,
          },
          listColumnFilters: {
            ...initialState.listColumnFilters,
            ...persistedListColumnFilters,
          },
          listPagination: {
            ...initialState.listPagination,
            ...persistedListPagination,
          },
          ...unhydratedInitialState,
        };
      })
      .addDefaultCase((state) => state);
  },
});

export const { settingsSet, settingsReset } = settingsSlice.actions;
export default settingsSlice.reducer;
