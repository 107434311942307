import { useMemo } from 'react';
import { Navigate, useRoutes } from 'react-router';
import { useSelector } from 'react-redux';
import compact from 'lodash.compact';

import Page404 from './containers/page_404';

import Home from './containers/home';
import Logout from './containers/logout';
import Login from './containers/login';

import BomImportForm from './containers/bom_import_form';
import CanadianSolarImportForm from './containers/canadian_solar_import_form';
import WbsImportForm from './containers/wbs_code_import_form';

import SrackImportForm from './containers/srack_import_form';
import SrackUpdateImportForm from './containers/srack_update_import_form';

import ConsignmentItemSummaryReport from './containers/reports/consignment_item_summary_report';
import ConsignmentItemByDescriptionSummaryReport from './containers/reports/consignment_item_by_description_summary_report';
import ConsignmentItemBySiteLocationSummaryReport from './containers/reports/consignment_item_by_site_location_summary_report';
import UnreceiptedConsignmentItemsReport from './containers/reports/unreceipted_consignment_items_report';
import DuplicateReceiptedConsignmentItemdReport from './containers/reports/duplicate_receipted_consignment_items_report';
import TableStatusSummaryReport from './containers/reports/table_status_summary_report';
import TableStatusSummaryByZoneReport from './containers/reports/table_status_summary_by_zone_report';
import PilingStatusSummaryReport from './containers/reports/piling_status_summary_report';
import PilingByDayReport from './containers/reports/piling_by_day_report';
import ExceptionsReport from './containers/reports/exception_reports';

import ConsignmentList from './containers/consignment_list';
import ConsignmentShow from './containers/consignment_show';
import ConsignmentForm from './containers/consignment_form';
import ConsignmentItemList from './containers/consignment_item_list';
import ConsignmentItemForm from './containers/consignment_item_form';
import ConsignmentImageList from './containers/consignment_image_list';
import ConsignmentImageForm from './containers/consignment_image_form';
import InverterList from './containers/inverter_list';
import InverterForm from './containers/inverter_form';
import PicklistList from './containers/picklist_list';
import PicklistForm from './containers/picklist_form';
import PicklistShow from './containers/picklist_show';
import PicklistTemplateList from './containers/picklist_template_list';
import PicklistTemplateForm from './containers/picklist_template_form';
import PilingMachineList from './containers/piling_machine_list';
import PilingMachineForm from './containers/piling_machine_form';
import PileList from './containers/pile_list';
import PileForm from './containers/pile_form';
import SiteMap from './containers/site_map';
import PileImportForm from './containers/pile_import_form';
import ReceiptList from './containers/receipt_list';
import ReceiptShow from './containers/receipt_show';
import ReceiptForm from './containers/receipt_form';
import GoodsReceiptShow from './containers/goods_receipt_show';
import ProductList from './containers/product_list';
import ProductForm from './containers/product_form';
import SiteList from './containers/site_list';
import SiteForm from './containers/site_form';
import SiteLocationList from './containers/site_location_list';
import SiteLocationForm from './containers/site_location_form';
import ProductCategoryList from './containers/product_category_list';
import ProductCategoryForm from './containers/product_category_form';
import PurchaserList from './containers/purchaser_list';
import PurchaserForm from './containers/purchaser_form';
import ManufacturerList from './containers/manufacturer_list';
import ManufacturerForm from './containers/manufacturer_form';
import SupplierList from './containers/supplier_list';
import SupplierForm from './containers/supplier_form';
import SupplierCatalogList from './containers/supplier_catalog_list';
import SupplierCatalogForm from './containers/supplier_catalog_form';
import SupplierCatalogItemList from './containers/supplier_catalog_item_list';
import SupplierCatalogItemForm from './containers/supplier_catalog_item_form';
import TableList from './containers/table_list';
import TableForm from './containers/table_form';
import TableImportForm from './containers/table_import_form';
import ManageTableList from './containers/manage_table_list';
import UserList from './containers/user_list';
import UserForm from './containers/user_form';
import VisitorLogList from './containers/visitor_log_list';

import WbsCodeList from './containers/wbs_code_list';
import WbsCodeForm from './containers/wbs_code_form';

import AuthWrapper from './layouts/auth_wrapper';
import AuthLayout from './layouts/auth_layout';
import FluidAndScrollLayout from './layouts/fluid_and_scroll_layout';

const auth = (Component) => (
  <AuthWrapper>
    <Component />
  </AuthWrapper>
);

const restfulAuthRoutes = ({
  path,
  listElement,
  showElement,
  newElement,
  editElement,
  singleton = false,
}) => ({
  path,
  children: compact([
    newElement && {
      path: 'new',
      element: auth(newElement),
    },
    showElement && {
      path: singleton ? '' : ':id',
      element: auth(showElement),
    },
    editElement && {
      path: singleton ? 'edit' : ':id/edit',
      element: auth(editElement),
    },
    !singleton &&
      listElement && {
        path: '',
        element: auth(listElement),
      },
  ]),
});

const AppRoutes = () => {
  const settingsDefaultHomeRoute = useSelector(
    (state) => state.settings.defaultHomeRoute
  );
  const settingsDevannerHomeRoute = useSelector(
    (state) => state.settings.devannerHomeRoute
  );
  const currentUser = useSelector((state) => state.auth.user);

  const homeRoute = useMemo(() => {
    if (currentUser?.perms) {
      if (
        Object.entries(currentUser.perms).filter(([k, v]) => k !== 'devanner' && v)
          .length > 0
      ) {
        return settingsDefaultHomeRoute;
      }
      if (currentUser.perms.devanner) {
        return settingsDevannerHomeRoute;
      }
    }
    return '/login';
  }, [currentUser, settingsDefaultHomeRoute, settingsDevannerHomeRoute]);

  return useRoutes([
    {
      path: '/',
      element: <Navigate to={homeRoute} />,
    },
    {
      path: '/logout',
      element: <Navigate to="/auth/logout" />,
    },
    {
      path: '/login',
      element: <Navigate to="/auth/login" />,
    },
    {
      path: '/404',
      element: <Page404 />,
    },
    {
      path: '/auth',
      element: <AuthLayout />,
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'logout',
          element: <Logout />,
        },
      ],
    },
    {
      path: '/reports',
      element: <FluidAndScrollLayout />,
      children: [
        {
          path: 'table_status_summary',
          element: <TableStatusSummaryReport />,
        },
        {
          path: 'table_status_summary_by_zone',
          element: <TableStatusSummaryByZoneReport />,
        },
        {
          path: 'piling_status_summary',
          element: <PilingStatusSummaryReport />,
        },
        {
          path: 'piling_by_day',
          element: <PilingByDayReport />,
        },
        {
          path: 'exceptions',
          element: <ExceptionsReport />,
        },
        {
          path: 'consignment_item_summary',
          element: <ConsignmentItemSummaryReport />,
        },
        {
          path: 'consignment_item_by_description_summary',
          element: <ConsignmentItemByDescriptionSummaryReport />,
        },
        {
          path: 'consignment_item_by_site_location_summary',
          element: <ConsignmentItemBySiteLocationSummaryReport />,
        },
        {
          path: 'unreceipted_consignment_items',
          element: <UnreceiptedConsignmentItemsReport />,
        },
        {
          path: 'duplicate_receipted_consignment_items',
          element: <DuplicateReceiptedConsignmentItemdReport />,
        },
      ],
    },
    {
      path: '/imports',
      element: <FluidAndScrollLayout />,
      children: [
        {
          path: 'bom',
          element: <BomImportForm />,
        },
        {
          path: 'srack',
          element: <SrackImportForm />,
        },
        {
          path: 'srack_update',
          element: <SrackUpdateImportForm />,
        },
        {
          path: 'canadian_solar',
          element: <CanadianSolarImportForm />,
        },
        {
          path: 'pile',
          element: <PileImportForm />,
        },
        {
          path: 'table',
          element: <TableImportForm />,
        },
        {
          path: 'wbs_code',
          element: <WbsImportForm />,
        },
      ],
    },
    {
      element: <FluidAndScrollLayout />,
      children: [
        {
          path: '/home',
          element: <Home />,
        },
        {
          path: '/manage_table',
          element: <ManageTableList />,
        },
        {
          path: '/goods_receipt',
          element: <GoodsReceiptShow />,
        },
        restfulAuthRoutes({
          path: '/consignments',
          listElement: ConsignmentList,
          showElement: ConsignmentShow,
          newElement: ConsignmentForm,
          editElement: ConsignmentForm,
        }),
        restfulAuthRoutes({
          path: '/consignment_items',
          listElement: ConsignmentItemList,
          newElement: ConsignmentItemForm,
          editElement: ConsignmentItemForm,
        }),
        restfulAuthRoutes({
          path: '/consignment_images',
          listElement: ConsignmentImageList,
          newElement: ConsignmentImageForm,
          editElement: ConsignmentImageForm,
        }),
        restfulAuthRoutes({
          path: '/inverters',
          listElement: InverterList,
          newElement: InverterForm,
          editElement: InverterForm,
        }),
        restfulAuthRoutes({
          path: '/picklists',
          listElement: PicklistList,
          newElement: PicklistForm,
          editElement: PicklistForm,
          showElement: PicklistShow,
        }),
        restfulAuthRoutes({
          path: '/picklist_templates',
          listElement: PicklistTemplateList,
          newElement: PicklistTemplateForm,
          editElement: PicklistTemplateForm,
        }),
        restfulAuthRoutes({
          path: '/piles',
          listElement: PileList,
          newElement: PileForm,
          editElement: PileForm,
        }),
        restfulAuthRoutes({
          path: '/site_map',
          singleton: true,
          showElement: SiteMap,
        }),
        restfulAuthRoutes({
          path: '/piling_machines',
          listElement: PilingMachineList,
          newElement: PilingMachineForm,
          editElement: PilingMachineForm,
        }),
        restfulAuthRoutes({
          path: '/products',
          listElement: ProductList,
          newElement: ProductForm,
          editElement: ProductForm,
        }),
        restfulAuthRoutes({
          path: '/receipts',
          listElement: ReceiptList,
          showElement: ReceiptShow,
          newElement: ReceiptForm,
          editElement: ReceiptForm,
        }),
        restfulAuthRoutes({
          path: '/sites',
          listElement: SiteList,
          newElement: SiteForm,
          editElement: SiteForm,
        }),
        restfulAuthRoutes({
          path: '/site_locations',
          listElement: SiteLocationList,
          newElement: SiteLocationForm,
          editElement: SiteLocationForm,
        }),
        restfulAuthRoutes({
          path: '/product_categories',
          listElement: ProductCategoryList,
          newElement: ProductCategoryForm,
          editElement: ProductCategoryForm,
        }),
        restfulAuthRoutes({
          path: '/purchasers',
          listElement: PurchaserList,
          newElement: PurchaserForm,
          editElement: PurchaserForm,
        }),
        restfulAuthRoutes({
          path: '/manufacturers',
          listElement: ManufacturerList,
          newElement: ManufacturerForm,
          editElement: ManufacturerForm,
        }),
        restfulAuthRoutes({
          path: '/suppliers',
          listElement: SupplierList,
          newElement: SupplierForm,
          editElement: SupplierForm,
        }),
        restfulAuthRoutes({
          path: '/supplier_catalogs',
          listElement: SupplierCatalogList,
          newElement: SupplierCatalogForm,
          editElement: SupplierCatalogForm,
        }),
        restfulAuthRoutes({
          path: '/supplier_catalog_items',
          listElement: SupplierCatalogItemList,
          newElement: SupplierCatalogItemForm,
          editElement: SupplierCatalogItemForm,
        }),
        restfulAuthRoutes({
          path: '/tables',
          listElement: TableList,
          newElement: TableForm,
          editElement: TableForm,
        }),
        restfulAuthRoutes({
          path: '/users',
          listElement: UserList,
          newElement: UserForm,
          editElement: UserForm,
        }),
        restfulAuthRoutes({
          path: '/visitor_logs',
          listElement: VisitorLogList,
        }),
        restfulAuthRoutes({
          path: '/wbs_codes',
          listElement: WbsCodeList,
          newElement: WbsCodeForm,
          editElement: WbsCodeForm,
        }),
      ],
    },
    {
      path: '/*',
      element: <Navigate to="/404" />,
    },
  ]);
};

export default AppRoutes;
