import { useMemo, useRef } from 'react';
import {
  Col,
  Row,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Table,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useSelector } from 'react-redux';
import { sentenceCase } from 'change-case';
import { useReactToPrint } from 'react-to-print';
import { Printer as PrinterIcon, RefreshCw as RefreshCwIcon } from 'react-feather';

import get from 'lodash.get';

import {
  renderOverlay,
  renderOffline,
  renderError,
} from '../../components/render_helpers';

import { pilingReportPageQuery } from '../../graphql/report_queries';

const TableStatusSummaryByZoneReport = () => {
  const printRef = useRef();
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);

  const handlePrint = useReactToPrint({
    contentRef: printRef,
  });

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pilingReportPageQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const reportData = useMemo(
    () => get(pageData, ['tableSummaryByZoneReport', 'report', 'data'], {}),
    [pageData]
  );

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <h1 className="h3 mb-3">Table Status By Zone Report</h1>
        </Col>
        <Col className="noprint" sm="auto">
          <ButtonToolbar>
            <ButtonGroup className="me-2">
              <Button title="Print report or save as PDF" onClick={handlePrint}>
                <PrinterIcon />
              </Button>
            </ButtonGroup>
            <ButtonGroup className="me-0">
              <Button
                title="Refresh data"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                <RefreshCwIcon />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey="tableSummaryByZoneReport-1"
            id="reports"
            className="mb-3"
          >
            {Object.keys(reportData).map((zoneKey) => {
              const zoneData = reportData[zoneKey];
              const zoneRows = get(zoneData, 'rows', '');
              const zoneTotal = get(zoneData, 'total', '');
              return (
                <Tab
                  key={`tableSummaryByZoneReport-${zoneKey}`}
                  eventKey={`tableSummaryByZoneReport-${zoneKey}`}
                  title={`Zone ${zoneKey}`}
                >
                  <p>{`Zone Tables: ${zoneTotal}`}</p>
                  <Table style={{ width: '70%' }}>
                    <thead>
                      <tr>
                        <th>Table Status</th>
                        <th className="text-end" style={{ width: '15%' }}>
                          Number in State
                        </th>
                        <th className="text-end" style={{ width: '15%' }}>
                          Percent in State
                        </th>
                        <th className="text-end" style={{ width: '15%' }}>
                          Percent of Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {zoneRows.map((row) => {
                        const {
                          status,
                          length,
                          percent,
                          accumPercent,
                          completeStatus,
                          inProgressStatus,
                          nonConformingStatus,
                        } = row;
                        let cellAccumPercent = '-';
                        if (completeStatus && (accumPercent || accumPercent === 0)) {
                          cellAccumPercent = `${accumPercent}%`;
                        }
                        return (
                          <tr key={status}>
                            {completeStatus && (
                              <td>
                                <span style={{ fontStyle: 'bold' }}>
                                  {sentenceCase(status)}
                                </span>
                              </td>
                            )}
                            {(nonConformingStatus || inProgressStatus) && (
                              <td>
                                <span
                                  style={{ fontStyle: 'italic' }}
                                  className="ml-2 small"
                                >
                                  &nbsp;&nbsp;{sentenceCase(status)}
                                </span>
                              </td>
                            )}
                            {!completeStatus &&
                              !nonConformingStatus &&
                              !inProgressStatus && <td>{sentenceCase(status)}</td>}
                            <td className="text-end">{length}</td>
                            <td className="text-end">{percent && `${percent}%`}</td>
                            <td className="text-end">{cellAccumPercent}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Tab>
              );
            })}
          </Tabs>
        </Col>
      </Row>
    </>
  );

  return (
    <div ref={printRef}>
      <style>{`@media print {.noprint{display: none;}}`}</style>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default TableStatusSummaryByZoneReport;
