import { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import Chart from 'react-apexcharts';

import { sentenceCase } from 'change-case';
import get from 'lodash.get';
import defaultsDeep from 'lodash.defaultsdeep';

import defaultOptions from './default_270_radial_options';

const MechanicalSummaryRadialChart = ({ pageData, TableBuildStatuses }) => {
  const chartData = useMemo(() => {
    if (pageData?.tableSummaryReport) {
      const tableData = get(pageData, 'tableSummaryReport.report.data.rows');
      const contractorCompleteRow = tableData.find(
        (d) => d.status === TableBuildStatuses.CONTRACTOR_MECHANICAL_CHECKLIST_COMPLETE
      );
      const inspectorCompleteRow = tableData.find(
        (d) => d.status === TableBuildStatuses.INSPECTOR_MECHANICAL_CHECKLIST_COMPLETE
      );
      const newChartData = {
        checklistComplete: contractorCompleteRow.accumPercent,
        inspectorAccepted: inspectorCompleteRow.accumPercent,
      };
      return newChartData;
    }
    return {};
  }, [pageData, TableBuildStatuses]);

  const series = Object.values(chartData);
  const options = defaultsDeep(
    {
      labels: Object.keys(chartData).map((k) => sentenceCase(k)),
      title: { text: 'Mechanical Summary' },
    },
    defaultOptions
  );

  return (
    <Card>
      <Card.Body>
        <Chart options={options} series={series} type="radialBar" height="350" />
      </Card.Body>
    </Card>
  );
};

export default MechanicalSummaryRadialChart;
