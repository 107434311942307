import { useEffect, useMemo, useState } from 'react';
import {
  Navbar,
  Nav,
  NavDropdown,
  OverlayTrigger,
  Tooltip,
  Button,
  Badge,
  Modal,
  ButtonGroup,
  ButtonToolbar,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { kebabCase } from 'change-case';

import {
  AlertCircle as AlertCircleIcon,
  Wifi as WifiIcon,
  WifiOff as WifiOffIcon,
  // Sliders as SlidersIcon,
  // Users as UsersIcon,
  // Lock as LockIcon,
} from 'react-feather';

import LinkContainer from '../components/link_container';
import Confirm from '../components/confirm';
import DlHorizontal from '../components/dl_horizontal';
import { clearLocalStorage, clearLocalSettings } from '../lib/local_storage';
// import Glyphicon from '../components/glyphicon';
// import { settingsSet } from '../store/settings_slice';
import { releaseNotes } from '../release_notes';

import { renderMultlilineText } from '../lib/utils';

export default function Header() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showReleaseModalModal, setShowReleaseModal] = useState(false);
  const settingsOnline = useSelector((state) => state.settings.online);
  const currentUser = useSelector((state) => state.auth.user);
  const versionCurrent = useSelector((state) => state.settings.versionCurrent);
  const trackedMutationCount = useSelector(
    (state) => state.settings.trackedMutationCount
  );
  const currentBootswatchStyle = useSelector((state) => state.settings.bootswatchStyle);
  const tenantShortName = useSelector((state) => state.settings.tenantShortName);

  const perms = useMemo(() => {
    if (currentUser?.id) {
      return currentUser.perms;
    }
    return {};
  }, [currentUser]);

  useEffect(() => {
    const link = document.querySelector('link[title=bootswatch]');
    if (link) {
      const href = link.getAttribute('href');
      if (href) {
        const newHref = href.replace(
          /dist\/.+\/bootstrap.min.css/,
          `dist/${currentBootswatchStyle}/bootstrap.min.css`
        );
        link.setAttribute('href', newHref);
      }
    }
  }, [currentBootswatchStyle]);

  const handleReleaseModalClick = () => {
    setShowReleaseModal(true);
  };

  const handleReleaseModalCancel = () => {
    setShowReleaseModal(false);
  };

  const onClearStorageClicked = async () => {
    await clearLocalStorage();
    window.location.reload(true);
  };

  const onClearSettingsClicked = async () => {
    await clearLocalSettings();
    window.location.reload(true);
  };

  const onResetClicked = () => {
    navigate('/auth/logout?reset');
  };

  const onReloadClicked = () => {
    window.location.reload(true);
  };

  // const handleStyleChange = (bootswatchStyle) =>
  //   dispatch(
  //     settingsSet({
  //       bootswatchStyle,
  //     })
  //   );

  const routes = [
    {
      name: 'Home',
      path: '/',
    },
    {
      name: 'Status Map',
      path: '/site_map',
      role: 'manager',
    },
    {
      name: 'Consignments',
      role: 'manager',
      children: [
        {
          name: 'Receive Inbound Item',
          path: '/goods_receipt',
        },
        {
          name: 'All Receipts',
          path: '/receipts',
        },
        { divider: true },
        {
          name: 'Consignments',
          path: '/consignments',
        },
        {
          name: 'Consignment Items',
          path: '/consignment_items',
        },
        {
          name: 'Consignment Images',
          path: '/consignment_images',
        },
        { divider: true },
        {
          name: 'Picklists',
          path: '/picklists',
        },
        {
          name: 'Picklist Templates',
          path: '/picklist_templates',
          role: 'administrator',
        },
      ],
    },
    {
      name: 'Products',
      role: 'manager',
      children: [
        {
          name: 'Products',
          path: '/products',
        },
        {
          name: 'Product Categories',
          path: '/product_categories',
        },
        { divider: true },
        {
          name: 'Purchasers',
          path: '/purchasers',
        },
        {
          name: 'Manufacturers',
          path: '/manufacturers',
        },
        { divider: true },
        {
          name: 'Suppliers',
          path: '/suppliers',
        },
        {
          name: 'Supplier Catalogs',
          path: '/supplier_catalogs',
        },
        {
          name: 'Supplier Catalog Items',
          path: '/supplier_catalog_items',
        },
        { divider: true },
        {
          name: 'WBS Codes',
          path: '/wbs_codes',
        },
      ],
    },
    {
      name: 'Reports',
      role: 'manager',
      children: [
        {
          name: 'Consignment Item Summary Report',
          path: '/reports/consignment_item_summary',
        },
        {
          name: 'Consignment Item By SKU Report',
          path: '/reports/consignment_item_by_description_summary',
        },
        {
          name: 'Consignment Item SKUs by Site Location Report',
          path: '/reports/consignment_item_by_site_location_summary',
        },
        {
          name: 'Unreceipted Consignment Items Report',
          path: '/reports/unreceipted_consignment_items',
        },
        {
          name: 'Duplicate Receipted Consignment Items Report',
          path: '/reports/duplicate_receipted_consignment_items',
        },
        { divider: true },
        {
          name: 'Table Status Summary Report',
          path: '/reports/table_status_summary',
        },
        {
          name: 'Table Status Summary By Zone Report',
          path: '/reports/table_status_summary_by_zone',
        },
        {
          name: 'Piling Status Summary Report',
          path: '/reports/piling_status_summary',
        },
        {
          name: 'Piling By Day Report',
          path: '/reports/piling_by_day',
        },
        { divider: true },
        {
          name: 'Exception and Data Quality Reports',
          path: '/reports/exceptions',
          role: 'administrator',
        },
      ],
    },
    {
      name: 'Admin',
      role: 'administrator',
      children: [
        {
          name: 'Users',
          path: '/users',
        },
        {
          name: 'Visitor Logs',
          path: '/visitor_logs',
        },
        { divider: true },
        {
          name: 'Sites',
          path: '/sites',
        },
        {
          name: 'Site Locations',
          path: '/site_locations',
        },
        { divider: true },
        {
          name: 'Piling Machines',
          path: '/piling_machines',
        },
        {
          name: 'Piles (slow load)',
          path: '/piles',
        },
        { divider: true },
        {
          name: 'Inverters',
          path: '/inverters',
        },
        {
          name: 'Tables',
          path: '/tables',
        },
      ],
    },
    {
      name: 'Developer',
      role: 'developer',
      children: [
        {
          name: 'Bom Import',
          path: '/imports/bom',
        },
        {
          name: 'Srack Import',
          path: '/imports/srack',
        },
        {
          name: 'Srack Loading Plan Import',
          path: '/imports/srack_update',
        },
        {
          name: 'Canadian Solar Import',
          path: '/imports/canadian_solar',
        },
        {
          name: 'WBS Code Import',
          path: '/imports/wbs_code',
        },
        {
          name: 'Pile Import',
          path: '/imports/pile',
        },
        {
          name: 'Table Import',
          path: '/imports/table',
        },
        {
          name: 'Manage Tables (old)',
          path: '/manage_table',
        },
      ],
    },
  ];

  return (
    <>
      <Modal
        id="header-release-notes"
        show={showReleaseModalModal}
        onHide={handleReleaseModalCancel}
        centered
        size="lg"
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Release Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: 'calc(66vh)', overflowY: 'scroll' }}>
            {releaseNotes.map(({ releasedOn, releaseNote }) => (
              <DlHorizontal
                key={releasedOn}
                dtWidth={3}
                ddWidth={9}
                dt={releasedOn}
                dd={renderMultlilineText(releaseNote)}
              />
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="d-flex justify-content-end w-100">
            <ButtonGroup className="me-2">
              <Button variant="danger" onClick={handleReleaseModalCancel}>
                Close
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>

      <Navbar
        // defaultExpanded={false}
        expand="md"
        variant="light"
        bg="light"
        className="px-4"
      >
        <Navbar.Brand href="/" className="text-dark">
          {tenantShortName}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <div className="w-100 d-flex justify-content-between flex-nowrap">
          <Navbar.Collapse id="left-navbar-nav" className="justify-content-start">
            <Nav>
              {routes.map(
                (
                  { name: headerName, role: headerRole, path: headerPath, children },
                  headerIndex
                ) => {
                  /* eslint-disable react/no-array-index-key */
                  if (!headerRole || perms[headerRole]) {
                    if (headerPath) {
                      return (
                        <Nav.Item key={kebabCase(headerName)} className="me-2">
                          <LinkContainer to={{ pathname: headerPath }}>
                            <Nav.Link>{headerName}</Nav.Link>
                          </LinkContainer>
                        </Nav.Item>
                      );
                    }
                    if (children) {
                      return (
                        <NavDropdown
                          key={kebabCase(headerName)}
                          title={headerName}
                          id={`nav-dropdown-${kebabCase(headerName)}`}
                          className="me-2"
                        >
                          {children.map(
                            (
                              { name: itemName, role: itemRole, path: itemPath, divider },
                              itemIndex
                            ) => {
                              if (!itemRole || perms[itemRole]) {
                                if (divider) {
                                  return (
                                    <NavDropdown.Divider
                                      key={`${kebabCase(headerName)}-${headerIndex}-${itemIndex}`}
                                    />
                                  );
                                }
                                return (
                                  <LinkContainer
                                    key={kebabCase(itemName)}
                                    to={{ pathname: itemPath }}
                                  >
                                    <NavDropdown.Item
                                      eventKey={`${headerIndex}-${itemIndex}`}
                                    >
                                      {itemName}
                                    </NavDropdown.Item>
                                  </LinkContainer>
                                );
                              }
                              return undefined;
                            }
                          )}
                        </NavDropdown>
                      );
                    }
                  }
                  return undefined;
                }
              )}
              {versionCurrent || (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-header-version-link">
                      Your application version is out of date. Click to refresh your
                      browser
                    </Tooltip>
                  }
                >
                  <Button variant="danger" onClick={onReloadClicked}>
                    <AlertCircleIcon size="16" />
                  </Button>
                </OverlayTrigger>
              )}
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse id="right-navbar-nav" className="justify-content-end">
            <Nav>
              <Nav.Item className="me-3">
                {settingsOnline ? (
                  <OverlayTrigger
                    key="online"
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-online">You are online</Tooltip>}
                  >
                    <WifiIcon />
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    key="offline"
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-offline">
                        You are offline. Not all data may be available
                      </Tooltip>
                    }
                  >
                    <WifiOffIcon color="red" />
                  </OverlayTrigger>
                )}
              </Nav.Item>
              <Nav.Item className="me-2">
                <OverlayTrigger
                  key="online"
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-online">Sync Queue</Tooltip>}
                >
                  <Badge
                    pill
                    variant={trackedMutationCount > 0 ? 'danger' : 'secondary'}
                    size="lg"
                  >
                    {trackedMutationCount}
                  </Badge>
                </OverlayTrigger>
              </Nav.Item>
            </Nav>
            {/* <Nav activeKey={currentBootswatchStyle} onSelect={handleStyleChange}>
            <NavDropdown title="Themes" id="basic-nav-dropdown-themes">
              <NavDropdown.Item eventKey="simplex">Simplex</NavDropdown.Item>
              <NavDropdown.Item eventKey="sandstone">Sandstone</NavDropdown.Item>
              <NavDropdown.Item eventKey="darkly">Darkly</NavDropdown.Item>
              <NavDropdown.Item eventKey="zephyr">Zephyr</NavDropdown.Item>
              <NavDropdown.Item eventKey="cosmo">Cosmo</NavDropdown.Item>
              <NavDropdown.Item eventKey="flatly">Flatly</NavDropdown.Item>
              <NavDropdown.Item eventKey="sketchy">Sketchy</NavDropdown.Item>
              <NavDropdown.Item eventKey="spacelab">Spacelab</NavDropdown.Item>
              <NavDropdown.Item eventKey="journal">Journal</NavDropdown.Item>
              <NavDropdown.Item eventKey="morph">Morph</NavDropdown.Item>
              <NavDropdown.Item eventKey="yeti">Yeti</NavDropdown.Item>
            </NavDropdown>
          </Nav> */}
            <Nav>
              <NavDropdown align="end" title="Tools" id="basic-nav-dropdown-other">
                <NavDropdown.Header>{currentUser.email}</NavDropdown.Header>
                <NavDropdown.Item
                  eventKey={8.1}
                  href="#"
                  onClick={handleReleaseModalClick}
                >
                  Release Notes
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey={8.2}
                  href="#"
                  onClick={() => alert('Coming soon...')}
                >
                  Change password
                </NavDropdown.Item>

                <NavDropdown.Item eventKey={8.3} href="#" onClick={onClearStorageClicked}>
                  Clear Cache
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey={8.3}
                  href="#"
                  onClick={onClearSettingsClicked}
                >
                  Clear Settings
                </NavDropdown.Item>
                <Confirm
                  onConfirm={onResetClicked}
                  title="Reset all"
                  body="This will reset the service and log you out. Any changes made while offline will be lost."
                >
                  <NavDropdown.Item eventKey={8.4} href="#">
                    Reset
                  </NavDropdown.Item>
                </Confirm>
                <LinkContainer to={{ pathname: '/auth/logout' }}>
                  <NavDropdown.Item eventKey={8.5}>Sign Out</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
}
