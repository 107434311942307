import { Modal, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';

import Field from '../../components/form/rff_field';
import InputField from '../../components/form/input_field';
import CheckboxInputField from '../../components/form/checkbox_input_field';
import ReactDateTimeField from '../../components/form/react_date_time_field';
import SubmitButtonSpinner from '../../components/submit_button_spinner';

const PicklistReleaseFormModal = (props) => {
  const {
    show,
    onCancel,
    onComplete,
    pickReleased,
    pickReleasedNotes,
    pickReleasedAt,
    pickReleasedByUserId,
  } = props;

  const onFormSubmit = async (data) => {
    onComplete(data);
  };

  return (
    <Modal
      id="pick-release-form"
      show={show}
      onHide={onCancel}
      centered
      size="lg"
      animation={false}
    >
      <FinalForm
        onSubmit={onFormSubmit}
        initialValues={{
          pickReleased,
          pickReleasedNotes,
          pickReleasedAt,
          pickReleasedByUserId,
        }}
        mutators={{ setFieldTouched }}
      >
        {({ handleSubmit, submitting, pristine }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Release Picklist to Contractor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Field
                type="checkbox"
                name="pickReleased"
                component={CheckboxInputField}
                size="lg"
                labelWidth={4}
                inputWidth={8}
              >
                Released
              </Field>
              <Field
                name="pickReleasedAt"
                labelWidth={4}
                inputWidth={4}
                size="lg"
                component={ReactDateTimeField}
                helpText="DD/MM/YYYY"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                closeOnSelect
              >
                Released On
              </Field>
              <Field
                name="pickReleasedNotes"
                component={InputField}
                labelWidth={4}
                inputWidth={8}
                size="lg"
                asElement="textarea"
                rows={4}
              >
                Release Notes
              </Field>
            </Modal.Body>
            <Modal.Footer>
              <ButtonToolbar className="d-flex justify-content-end w-100">
                <ButtonGroup className="me-2">
                  <Button variant="danger" onClick={onCancel} disabled={submitting}>
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  >
                    {submitting && <SubmitButtonSpinner />}
                    Update
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Modal.Footer>
          </form>
        )}
      </FinalForm>
    </Modal>
  );
};

export default PicklistReleaseFormModal;
