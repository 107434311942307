import { useMemo, useCallback, useState } from 'react';
import { Button, Col, Row, Card, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { RefreshCw as RefreshCwIcon, Table as TableIcon } from 'react-feather';
import { DateTime } from 'luxon';

import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import pick from 'lodash.pick';

import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { renderMultlilineText, coerceInput, handleSubmitError } from '../lib/utils';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import {
  picklistShowPageQuery,
  picklistUpdate as picklistUpdateMutation,
} from '../graphql/picklist_queries';
import * as updateFunctions from '../update_functions';
import DlHorizontal from '../components/dl_horizontal';
import PicklistSupplierCatalogItemList from './picklist_show/picklist_supplier_catalog_item_list';
import PicklistReleaseFormModal from './picklist_show/picklist_release_form_modal';

import { settingsSet, settingsReset } from '../store/settings_slice';

const PicklistShow = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPicklistReleaseFormModal, setShowPicklistReleaseFormModal] = useState(false);
  const currentUser = useSelector((state) => state.auth.user);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [picklistUpdate] = useMutation(picklistUpdateMutation);
  const tableStateKey = 'picklistShowPicklistSupplierCatalogItem';

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(picklistShowPageQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { picklistId: params.id },
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const perms = useMemo(() => {
    if (currentUser?.id) {
      return currentUser.perms;
    }
    return {};
  }, [currentUser]);

  const tableResetClicked = useCallback(() => {
    dispatch(settingsReset(['tableState', tableStateKey]));
  }, [tableStateKey, dispatch]);

  const onPicklistFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    const input = coerceInput({
      ...submitData,
      ...pick(pageData.picklist, [
        'picklistTemplateId',
        'fromSiteLocationId',
        'toSiteLocationId',
      ]),
    });
    const mutation = picklistUpdate;
    const mutationMessageAction = 'update';
    const mutationData = {
      variables: { id: params.id, input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'PicklistType',
        recordId: params.id || uuid,
        mutationType: 'UPDATE',
      },
      update: updateFunctions.picklistUpdate,
    };
    mutationData.optimisticResponse = updateFunctions.optimisticNew({
      mutationName: 'picklistUpdate',
      mutationData,
      currentData: pageData.picklist,
    });
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Picklist ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        pageRefetch();
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Picklist ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      pageRefetch();
    }
    return undefined;
  };

  const handlePicklistReleaseFormModalClick = () => {
    setShowPicklistReleaseFormModal(true);
  };

  const handlePicklistReleaseFormModalCancel = () => {
    setShowPicklistReleaseFormModal(false);
  };

  const handlePicklistReleaseFormModalComplete = (data) => {
    onPicklistFormSubmit(data);
    setShowPicklistReleaseFormModal(false);
  };

  const renderContent = () => {
    const {
      picklist: {
        toSiteLocationId,
        picklistNotes,
        pickReleased,
        pickReleasedNotes,
        pickReleasedByUserId,
        pickReleasedAt,
        pickReleasedByUser,
        fromSiteLocation: { name: fromSiteLocationName },
        toSiteLocation: { name: toSiteLocationName },
        picklistTemplate: { name: picklistTemplateName },
        picklistSupplierCatalogItems,
      },
      consignmentItemBySiteLocationSummaryReport,
    } = pageData;
    return (
      <>
        <PicklistReleaseFormModal
          show={showPicklistReleaseFormModal}
          onCancel={handlePicklistReleaseFormModalCancel}
          onComplete={handlePicklistReleaseFormModalComplete}
          settingsTenant={settingsTenant}
          settingsOnline={settingsOnline}
          pickReleased={pickReleased || false}
          pickReleasedNotes={pickReleasedNotes || ''}
          pickReleasedByUserId={pickReleasedByUserId || currentUser.id}
          pickReleasedAt={pickReleasedAt || DateTime.now().toISO()}
        />
        <Row className="mt-4 mb-3">
          <Col>
            <h1 className="h3 mb-3">{`Picklist Details for ${picklistTemplateName} from ${fromSiteLocationName} to ${toSiteLocationName}`}</h1>
          </Col>
          <Col className="noprint" sm="auto">
            <ButtonToolbar>
              <ButtonGroup className="me-2">
                <Button className="me-2" variant="primary" onClick={() => navigate(-1)}>
                  Picklists
                </Button>
              </ButtonGroup>
              <ButtonGroup className="me-0">
                <Button
                  title="Reset table filters and sorting"
                  onClick={tableResetClicked}
                >
                  <TableIcon />
                </Button>
                <Button
                  title="Refresh data"
                  onClick={() => pageRefetch()}
                  disabled={!settingsOnline}
                >
                  <RefreshCwIcon />
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>
        <Row>
          <hr />
        </Row>
        {perms.manager && (
          <Row className="mb-3">
            <Col sm={6}>
              <Card>
                <Card.Header>Picklist Actions</Card.Header>
                <Card.Body>
                  <Button
                    className="me-2"
                    type="button"
                    variant="primary"
                    onClick={handlePicklistReleaseFormModalClick}
                  >
                    Release To Contractor
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={6}>
              <Card>
                <Card.Header>Details</Card.Header>
                <Card.Body>
                  {/* <DlHorizontal dt="ID" dd={picklistId} />
                  <DlHorizontal dt="Pick From Location" dd={fromSiteLocationName} />
                  <DlHorizontal dt="Pick To Location" dd={toSiteLocationName} /> */}
                  <DlHorizontal dt="Released" dd={pickReleased ? 'Yes' : 'No'} />
                  <DlHorizontal
                    dt="Released On"
                    dd={
                      pickReleasedAt
                        ? DateTime.fromISO(pickReleasedAt).toLocaleString(
                            DateTime.DATE_SHORT
                          )
                        : '-'
                    }
                  />
                  <DlHorizontal
                    dt="Released By"
                    dd={get(pickReleasedByUser, 'fullName', '-')}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        <Row className="mb-3">
          <Col sm={12}>
            <Row className="mt-0">
              <Col sm={12}>
                <Card>
                  <Card.Header>Items</Card.Header>
                  <Card.Body>
                    <PicklistSupplierCatalogItemList
                      picklistSupplierCatalogItems={picklistSupplierCatalogItems}
                      currentUser={currentUser}
                      settingsTenant={settingsTenant}
                      settingsOnline={settingsOnline}
                      onUpdate={() => pageRefetch()}
                      tableStateKey={tableStateKey}
                      consignmentItemBySiteLocationSummaryReport={
                        consignmentItemBySiteLocationSummaryReport
                      }
                      toSiteLocationId={toSiteLocationId}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm={12}>
                <Card>
                  <Card.Header>Picklist Notes</Card.Header>
                  <Card.Body>{renderMultlilineText(picklistNotes)}</Card.Body>
                </Card>
              </Col>
            </Row>
            {pickReleased && (
              <Row className="mt-4">
                <Col sm={12}>
                  <Card>
                    <Card.Header>Picklist Release Notes</Card.Header>
                    <Card.Body>{renderMultlilineText(pickReleasedNotes)}</Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default PicklistShow;
