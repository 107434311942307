import { useMemo, useRef, useCallback } from 'react';
import { Col, Row, Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import {
  Printer as PrinterIcon,
  RefreshCw as RefreshCwIcon,
  Table as TableIcon,
} from 'react-feather';

import get from 'lodash.get';
import defaultTo from 'lodash.defaultto';

import {
  renderOverlay,
  renderOffline,
  renderError,
} from '../../components/render_helpers';
import ReactTable from '../../components/react_table/react_table';
import { pilingReportPageQuery } from '../../graphql/report_queries';
import { settingsReset } from '../../store/settings_slice';

const PilingReport = () => {
  const dispatch = useDispatch();
  const printRef = useRef();
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);

  const tableStateKey = 'pileByDateByPileMachineReport';

  const handlePrint = useReactToPrint({
    contentRef: printRef,
  });

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pilingReportPageQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const reportPilingMachines = useMemo(() => {
    if (pageData?.pilingMachineList) {
      return [{ id: 0, name: 'UNKNOWN' }, ...get(pageData, 'pilingMachineList', [])];
    }
    return [];
  }, [pageData]);

  const tableResetClicked = useCallback(() => {
    dispatch(settingsReset(['tableState', tableStateKey]));
  }, [tableStateKey, dispatch]);

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <h1 className="h3 mb-3">Piling By Date By Piling Machine Report</h1>
        </Col>
        <Col className="noprint" sm="auto">
          <ButtonToolbar>
            <ButtonGroup className="me-2">
              <Button title="Print report or save as PDF" onClick={handlePrint}>
                <PrinterIcon />
              </Button>
            </ButtonGroup>
            <ButtonGroup className="me-0">
              <Button title="Reset table filters and sorting" onClick={tableResetClicked}>
                <TableIcon />
              </Button>
              <Button
                title="Refresh data"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                <RefreshCwIcon />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactTable
            rootName={tableStateKey}
            parentColumns={[
              {
                header: 'Finished On',
                accessorKey: 'finishedOn',
                enableColumnFilter: false,
              },
              ...reportPilingMachines.map(({ id, name }) => ({
                header: name,
                accessorKey: id.toString(),
                enableColumnFilter: false,
                sortingFn: 'alphanumeric',
              })),
              {
                header: 'Total Piles',
                accessorKey: 'totalPiles',
                enableColumnFilter: false,
              },
              {
                header: 'Total EVA Minutes',
                accessorKey: 'durationMinutes',
                enableColumnFilter: false,
                sortingFn: 'alphanumeric',
              },
              {
                header: 'Minutes per Pile',
                accessorKey: 'minutesPerPile',
                enableColumnFilter: false,
                sortingFn: 'alphanumeric',
              },
            ]}
            data={defaultTo(
              get(pageData, ['pileByDateByPileMachineReport', 'report', 'data'], []),
              []
            )}
            doShow={false}
            doDelete={false}
            hideActions
            hideResetTable
          />
        </Col>
      </Row>
    </>
  );

  return (
    <div ref={printRef}>
      <style>{`@media print {.noprint{display: none;}}`}</style>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default PilingReport;
