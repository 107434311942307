export const releaseNotes = [
  {
    releasedOn: '2025-01-16',
    releaseNote: `\
Add Picklist release function and form
Add Consignment Item by Site Location report
`,
  },
  {
    releasedOn: '2025-01-15',
    releaseNote: `\
Add Table Summary by Zone report
Add Consigment Items by Description/SKU report
`,
  },
  {
    releasedOn: '2025-01-14',
    releaseNote: `\
Add print dialog to consignment, table and pile reports
Improve consignment report navigation
`,
  },
  {
    releasedOn: '2025-01-13',
    releaseNote: `\
Add mechanical and electrical table statuses
Improve report navigation
Move site map to top level
`,
  },
  {
    releasedOn: '2024-12-31',
    releaseNote: `\
Add graphs for KPIs
Update receiver role to devanner
`,
  },
  {
    releasedOn: '2024-12-12',
    releaseNote: `\
Update pile finish date if completed via procore inspection
Update piles report to show UNKNOWN for piling machine if unknown
`,
  },
  {
    releasedOn: '2024-12-11',
    releaseNote: `\
Update table status names
Poll procore checklists and update contractor and inspector statuses on table and piles
Add table summary report
Add procore inspection link to manage table modal
`,
  },
  {
    releasedOn: '2024-12-10',
    releaseNote: `\
Update pile status names
`,
  },
  {
    releasedOn: '2024-12-09',
    releaseNote: `\
Add build status filter to site map
Add pile total and EVA duration to pile by day by machine report
Add duration calculation method to visitor logs
`,
  },
  {
    releasedOn: '2024-12-06',
    releaseNote: `\
Fix signed url updating for consignment images
`,
  },
  {
    releasedOn: '2024-12-05',
    releaseNote: `\
Add visitor logs list
`,
  },
  {
    releasedOn: '2024-12-03',
    releaseNote: `\
Add filtering and table search to site map
Save current zoom state across reload
Add label to table at zoom 20
`,
  },
  {
    releasedOn: '2024-12-02',
    releaseNote: `\
Improve performance of site map
Improve creation of geojson feature collections
Add initial coloring of tables on map based on build state
Improve hover labelling on piles and tables
Add open street maps base layer (google rendering poorly)
Update table build status enums
`,
  },
  {
    releasedOn: '2024-12-01',
    releaseNote: `\
Add manage table
Add status and piles tabs to manage table popup
Add location map tab to manage table popup
`,
  },
  {
    releasedOn: '2024-11-30',
    releaseNote: `\
Add consignment item summary and exception reports
`,
  },
  {
    releasedOn: '2024-11-29',
    releaseNote: `\
Add pile summary report
Update menus
Move picklists to consignments menu
`,
  },
  {
    releasedOn: '2024-11-28',
    releaseNote: `\
Add table layer to map
Add tables importer
Add tables list and form
`,
  },
  {
    releasedOn: '2024-11-24',
    releaseNote: `\
Add piles by date by machine report
`,
  },
  {
    releasedOn: '2024-11-22',
    releaseNote: `\
Add piling machine and pile resources
Add piles importer
Add piles leaflet map
`,
  },
  {
    releasedOn: '2024-11-12',
    releaseNote: `\
Add wbscode management and importer
`,
  },
  {
    releasedOn: '2024-11-06',
    releaseNote: `\
Populate deliveredAt for consignment items based on their receipt record
Update consignment items and consignment list to use new table filter/sort/paginate
Improve natural sort of dropdown table entries
`,
  },
  {
    releasedOn: '2024-11-05',
    releaseNote: `\
Fix First Scanned sorting on receipts list
Add Last Seen column to receipts list
Add Man (manual) column to receipts list to allow filtering of receipts
Update supplier catalog item unit of measurement enums to include pallets
Record current user id when updating picked/unpicked/picked checked state changes on pick lists
Show unit of measurement on picklist show page (e.g. 400/bag)
Add this release notes modal
Add a 1 minute poll (auto refetch) to receipts list
`,
  },
];
