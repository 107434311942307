import { picklistProductNestedFormSchema } from './picklist_product_form_schema';

export const picklistFormSchema = {
  type: 'object',
  required: ['picklistTemplateId', 'fromSiteLocationId', 'toSiteLocationId'],
  properties: {
    picklistNotes: { type: 'string', minLength: 6, maxLength: 1000 },
    picklistReleaseNotes: { type: 'string', minLength: 6, maxLength: 1000 },
    // TODO this is not working not sure why check AF documents in checks
    picklistProducts: {
      type: 'array',
      items: picklistProductNestedFormSchema,
    },
  },
};
