/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import { useMemo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  Col,
  Row,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Table,
  Pagination,
  Form,
} from 'react-bootstrap';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  flexRender,
  getSortedRowModel,
  createColumnHelper,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
} from '@tanstack/react-table';
import { capitalCase, snakeCase, noCase } from 'change-case';
import pluralize from 'pluralize';

import get from 'lodash.get';
import isEqual from 'lodash.isequal';

import { StringFilter, DropdownFilter } from './react_table_filters';
import Confirm from '../confirm';
import { settingsSet, settingsReset } from '../../store/settings_slice';

const ReactTable = (props) => {
  const {
    parentColumns,
    data,
    rootName,
    // initialStateSorting,
    // initialStateColumnFilters,
    doShow = true,
    doEdit = true,
    doDelete = false,
    handleShow: parentHandleShow,
    handleEdit: parentHandleEdit,
    handleDelete: parentHandleDelete,
    excludeDeleteIds = [],
    hideResetTable = false,
    hideActions = false,
    actionCell,
    setRowStyles,
    setTableClasses,
    pageSizes = [10, 25, 50, 100],
  } = props;

  // const [editedTableData, setEditedTableData] = useState(data);
  const [gotoPage, setGotoPage] = useState(1);
  const dispatch = useDispatch();
  const settingsTableState = useSelector((state) => state.settings.tableState);
  const listColumnFilters = useSelector((state) => state.settings.listColumnFilters);
  const listPagination = useSelector((state) => state.settings.listPagination);
  const navigate = useNavigate();

  const tableResetClicked = useCallback(() => {
    dispatch(settingsReset(['tableState', rootName]));
  }, [rootName, dispatch]);

  const setColumnFilters = useCallback(
    (columnFiltersUpdateFn) => {
      const currentColumnFilters = get(settingsTableState, [rootName, 'columnFilters']);
      const newColumnFilters = columnFiltersUpdateFn(currentColumnFilters);
      if (!isEqual(currentColumnFilters, newColumnFilters)) {
        const newTableState = {
          ...settingsTableState,
          [rootName]: {
            ...settingsTableState[rootName],
            columnFilters: newColumnFilters,
          },
        };
        dispatch(
          settingsSet({
            tableState: newTableState,
          })
        );
      }
    },
    [rootName, settingsTableState, dispatch]
  );

  const setListColumnFilters = useCallback(
    (columnFiltersUpdateFn) => {
      const newColumnFilters = columnFiltersUpdateFn(listColumnFilters[rootName]);
      if (!isEqual(listColumnFilters[rootName], newColumnFilters)) {
        dispatch(
          settingsSet({
            listColumnFilters: {
              ...listColumnFilters,
              [rootName]: newColumnFilters,
            },
          })
        );
      }
    },
    [rootName, listColumnFilters, dispatch]
  );

  const setPagination = useCallback(
    (paginationUpdateFn) => {
      const currentPagination = get(settingsTableState, [rootName, 'pagination']);
      const newPagination = paginationUpdateFn(currentPagination);
      setGotoPage(newPagination.pageIndex + 1);
      if (!isEqual(currentPagination, newPagination)) {
        const newTableState = {
          ...settingsTableState,
          [rootName]: {
            ...settingsTableState[rootName],
            pagination: newPagination,
          },
        };
        dispatch(
          settingsSet({
            tableState: newTableState,
          })
        );
      }
    },
    [rootName, settingsTableState, dispatch]
  );

  const setListPagination = useCallback(
    (paginationUpdateFn) => {
      const newPagination = paginationUpdateFn(listPagination[rootName]);
      setGotoPage(newPagination.pageIndex + 1);
      if (!isEqual(listPagination[rootName], newPagination)) {
        const newListPagination = {
          ...listPagination,
          [rootName]: newPagination,
        };
        dispatch(
          settingsSet({
            listPagination: newListPagination,
          })
        );
      }
    },
    [rootName, listPagination, dispatch]
  );

  const setSorting = useCallback(
    (sortingUpdateFn) => {
      const currentSorting = get(settingsTableState, [rootName, 'sorting']);
      const newSorting = sortingUpdateFn(currentSorting);
      if (!isEqual(currentSorting, newSorting)) {
        const newTableState = {
          ...settingsTableState,
          [rootName]: {
            ...settingsTableState[rootName],
            sorting: newSorting,
          },
        };
        dispatch(
          settingsSet({
            tableState: newTableState,
          })
        );
      }
    },
    [rootName, settingsTableState, dispatch]
  );

  const handleShow = useCallback(
    (e) => {
      const id = e.currentTarget.getAttribute('data-id');
      if (parentHandleShow) {
        parentHandleShow(id);
      } else {
        navigate(`/${snakeCase(pluralize.plural(rootName))}/${id}`);
      }
    },
    [navigate, rootName, parentHandleShow]
  );

  const handleEdit = useCallback(
    (e) => {
      const id = e.currentTarget.getAttribute('data-id');
      if (parentHandleEdit) {
        parentHandleEdit(id);
      } else {
        // pluralize.addIrregularRule('user', 'users');
        navigate(`/${snakeCase(pluralize.plural(rootName))}/${id}/edit`);
      }
    },
    [navigate, rootName, parentHandleEdit]
  );

  const handleDelete = useCallback(
    (e) => {
      if (parentHandleDelete) {
        parentHandleDelete(e);
      } else {
        console.log('delete requested but no handler');
      }
    },
    [parentHandleDelete]
  );

  const renderActionCell = useCallback(
    (cellProps) => {
      const id = get(cellProps, 'row.original.id');
      if (!id) {
        return <span>Missing id...</span>;
      }
      return (
        <ButtonGroup key={id}>
          {doShow && (
            <Button variant="outline-primary" size="sm" data-id={id} onClick={handleShow}>
              show
            </Button>
          )}
          {doEdit && (
            <Button variant="outline-primary" size="sm" data-id={id} onClick={handleEdit}>
              edit
            </Button>
          )}
          {doDelete &&
            (excludeDeleteIds.length === 0 ||
              !excludeDeleteIds.includes(parseInt(id, 10))) && (
              <Confirm
                dataId={id}
                onConfirm={handleDelete}
                title={`Delete ${capitalCase(rootName)}`}
                body={`Are you sure you want to delete this ${noCase(rootName)}`}
              >
                <Button variant="outline-danger" size="sm">
                  delete
                </Button>
              </Confirm>
            )}
        </ButtonGroup>
      );
    },
    [
      rootName,
      doEdit,
      doShow,
      doDelete,
      handleShow,
      handleEdit,
      handleDelete,
      excludeDeleteIds,
    ]
  );

  const columns = useMemo(
    () => [
      ...parentColumns,
      ...(hideActions
        ? []
        : [
            createColumnHelper().display({
              id: 'actions',
              header: 'Actions',
              cell: actionCell || renderActionCell,
            }),
          ]),
    ],
    [hideActions, actionCell, renderActionCell, parentColumns]
  );

  const reactTable = useReactTable({
    columns,
    data,
    // data: editedTableData.length ? editedTableData : data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    // initialState: {
    //   ...(initialStateSorting && { sorting: initialStateSorting }),
    // },
    state: {
      ...(settingsTableState[rootName] && {
        pagination: get(settingsTableState, [rootName, 'pagination']),
        columnFilters: get(settingsTableState, [rootName, 'columnFilters']),
        sorting: get(settingsTableState, [rootName, 'sorting']),
      }),
      ...(!settingsTableState[rootName] && {
        pagination: listPagination[rootName],
        columnFilters: listColumnFilters[rootName],
      }),
    },
    // sorting,
    // },
    // meta: {
    //   updateData: (rowIndex, columnId, value) => {
    //     setEditedTableData((oldRows) =>
    //       oldRows.map((row, index) => {
    //         if (index === rowIndex) {
    //           return {
    //             ...oldRows[rowIndex],
    //             [columnId]: value,
    //           };
    //         }
    //         return row;
    //       })
    //     );
    //   },
    // },
    ...(settingsTableState[rootName] && {
      onColumnFiltersChange: setColumnFilters,
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
    }),
    ...(!settingsTableState[rootName] && {
      onColumnFiltersChange: setListColumnFilters,
      onPaginationChange: setListPagination,
    }),
  });

  return (
    <Row>
      {settingsTableState[rootName] && !hideResetTable && (
        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
          <Button size="sm" className="mb-2" variant="danger" onClick={tableResetClicked}>
            Reset Table
          </Button>
        </ButtonToolbar>
      )}
      <Col>
        <Table
          striped={!setRowStyles && !setTableClasses}
          className={setTableClasses && setTableClasses()}
          bordered
        >
          <thead>
            {reactTable.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const headerClassName = get(
                    header,
                    'column.columnDef.headerClassName',
                    'text-nowrap'
                  );
                  const { filterType } = header.column.columnDef;
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={headerClassName}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                          {header.column.getCanFilter() && filterType === 'dropdown' && (
                            <div>
                              <DropdownFilter column={header.column} table={reactTable} />
                            </div>
                          )}
                          {header.column.getCanFilter() && !filterType && (
                            <div>
                              <StringFilter column={header.column} />
                            </div>
                          )}
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {reactTable.getRowModel().rows.map((row) => (
              <tr key={row.id} style={setRowStyles && setRowStyles(row)}>
                {row.getVisibleCells().map((cell) => {
                  let cellClassName = cell?.column?.columnDef?.cellClassName;
                  const getCellClassName = cell?.column?.columnDef?.getCellClassName;
                  if (getCellClassName) {
                    cellClassName = getCellClassName(cell.getValue());
                  }
                  return (
                    <td
                      key={cell.id}
                      {...(cellClassName && { className: cellClassName })}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {reactTable.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => {
                  const footerClassName = get(
                    header,
                    'column.columnDef.footerClassName',
                    'text-nowrap'
                  );
                  return (
                    <th key={header.id} className={footerClassName}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.footer, header.getContext())}
                    </th>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        </Table>
        <Row className="noprint">
          <Col md="6">
            <span className="mx-2">
              Page{' '}
              <strong>
                {reactTable.getState().pagination.pageIndex + 1} of{' '}
                {reactTable.getPageCount()}
              </strong>
            </span>
            <span className="ms-3 me-2">Show:</span>
            <Form.Control
              as="select"
              className="d-inline-block w-auto"
              value={reactTable.getState().pagination.pageSize}
              onChange={(e) => {
                reactTable.setPageSize(Number(e.target.value));
              }}
            >
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Form.Control>

            <span className="ms-3 me-2">Go to page:</span>
            <Form.Control
              className="d-inline-block"
              type="number"
              min={1}
              max={reactTable.getPageCount()}
              value={gotoPage}
              onChange={(e) => {
                let pageNumber = e.target.value ? Number(e.target.value) : '';
                if (Number.isInteger(pageNumber)) {
                  pageNumber = Math.min(reactTable.getPageCount(), pageNumber);
                  pageNumber = Math.max(1, pageNumber);
                }
                setGotoPage(pageNumber);
              }}
              onBlur={(e) => {
                let pageNumber = e.target.value ? Number(e.target.value) : 1;
                pageNumber = Math.min(reactTable.getPageCount(), pageNumber);
                pageNumber = Math.max(1, pageNumber);
                reactTable.setPageIndex(pageNumber - 1);
              }}
              style={{ width: '75px' }}
            />
          </Col>
          <Col md="6">
            <Pagination className="float-end">
              <Pagination.First
                onClick={() => reactTable.setPageIndex(0)}
                disabled={!reactTable.getCanPreviousPage()}
              />
              <Pagination.Prev
                onClick={() => reactTable.previousPage()}
                disabled={!reactTable.getCanPreviousPage()}
              />
              <Pagination.Next
                onClick={() => reactTable.nextPage()}
                disabled={!reactTable.getCanNextPage()}
              />
              <Pagination.Last
                onClick={() => reactTable.setPageIndex(reactTable.getPageCount() - 1)}
                disabled={!reactTable.getCanNextPage()}
              />
            </Pagination>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ReactTable;
