import { useMemo, useRef } from 'react';
import { Col, Row, Button, ButtonGroup, ButtonToolbar, Table } from 'react-bootstrap';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useSelector } from 'react-redux';
import { sentenceCase } from 'change-case';
import { useReactToPrint } from 'react-to-print';
import { Printer as PrinterIcon, RefreshCw as RefreshCwIcon } from 'react-feather';

import get from 'lodash.get';

import {
  renderOverlay,
  renderOffline,
  renderError,
} from '../../components/render_helpers';

import { pilingReportPageQuery } from '../../graphql/report_queries';

const PilingReport = () => {
  const printRef = useRef();
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);

  const handlePrint = useReactToPrint({
    contentRef: printRef,
  });

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pilingReportPageQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <h1 className="h3 mb-3">Piling Status Summary Report</h1>
        </Col>
        <Col className="noprint" sm="auto">
          <ButtonToolbar>
            <ButtonGroup className="me-2">
              <Button title="Print report or save as PDF" onClick={handlePrint}>
                <PrinterIcon />
              </Button>
            </ButtonGroup>
            <ButtonGroup className="me-0">
              <Button
                title="Refresh data"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                <RefreshCwIcon />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table style={{ width: '50%' }}>
            <tbody>
              {get(pageData, ['pileSummaryReport', 'report', 'data'], []).map((row) => {
                const { status, length, percent } = row;
                return (
                  <tr key={status}>
                    <td>{sentenceCase(status)}</td>
                    <td className="text-end">{length}</td>
                    <td className="text-end">{percent && `${percent}%`}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );

  return (
    <div ref={printRef}>
      <style>{`@media print {.noprint{display: none;}}`}</style>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default PilingReport;
