import { Button, ButtonToolbar } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { validate as uuidValidate } from 'uuid';
import { useDispatch } from 'react-redux';
import { noCase } from 'change-case';
import pluralize from 'pluralize';

import cloneDeep from 'lodash.clonedeep';

import ReactTable from '../../components/react_table/react_table';

import { picklistSupplierCatalogItemUpdate as picklistSupplierCatalogItemUpdateMutation } from '../../graphql/picklist_supplier_catalog_item_queries';
import { toastSuccess, toastWarning, toastError } from '../../lib/toast_helpers';
import { settingsSet } from '../../store/settings_slice';
import * as updateFunctions from '../../update_functions';
import { coerceInput } from '../../lib/utils';

const PicklistSupplierCatalogItemList = (props) => {
  const {
    currentUser: { id: currentUserId },
    picklistSupplierCatalogItems,
    settingsTenant,
    settingsOnline,
    onUpdate,
    tableStateKey,
  } = props;

  const dispatch = useDispatch();
  const [picklistSupplierCatalogItemUpdate] = useMutation(
    picklistSupplierCatalogItemUpdateMutation
  );

  const picklistSupplierCatalogItemUpdateClicked = async (data) => {
    const { id } = data;
    const picklistSupplierCatalogItemId = uuidValidate(id) ? id : parseInt(id, 10);
    const currentItem = picklistSupplierCatalogItems.find(
      (psci) => psci.id === picklistSupplierCatalogItemId
    );
    const submitData = cloneDeep(data);
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { id: picklistSupplierCatalogItemId, input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'PicklistSupplierCatalogItemType',
        recordId: picklistSupplierCatalogItemId,
        mutationType: 'UPDATE',
      },
      update: updateFunctions.picklistSupplierCatalogItemUpdate,
    };
    mutationData.optimisticResponse = updateFunctions.optimisticNew({
      mutationName: 'picklistSupplierCatalogItemUpdate',
      mutationData,
      ...(currentItem && { currentData: currentItem }),
    });
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await picklistSupplierCatalogItemUpdate(mutationData);
        toastSuccess('Item updated');
      } catch (err) {
        console.log(err.toString());
        toastError('Item update failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        onUpdate();
      }
    } else {
      picklistSupplierCatalogItemUpdate(mutationData);
      toastWarning(`Item updated ok locally. Go online to make permanent`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      onUpdate();
    }
  };

  const renderActionsCell = ({ row: { original: row } }) => {
    const { id, picked, pickChecked } = row;
    return (
      <ButtonToolbar style={{ justifyContent: 'space-around' }}>
        {picked && (
          <Button
            variant="success"
            size="small"
            className="my-2"
            onClick={() =>
              picklistSupplierCatalogItemUpdateClicked({
                id,
                picked: false,
                pickChecked: false,
                pickedByUserId: currentUserId,
                pickCheckedByUserId: currentUserId,
              })
            }
          >
            Mark Unpicked
          </Button>
        )}
        {!picked && (
          <Button
            variant="danger"
            size="small"
            className="my-2"
            onClick={() =>
              picklistSupplierCatalogItemUpdateClicked({
                id,
                picked: true,
                pickChecked: false,
                pickedByUserId: currentUserId,
                pickCheckedByUserId: currentUserId,
              })
            }
          >
            Mark Picked
          </Button>
        )}
        {pickChecked && (
          <Button
            variant="success"
            size="small"
            className="my-2"
            disabled={!picked}
            onClick={() =>
              picklistSupplierCatalogItemUpdateClicked({
                id,
                pickChecked: false,
                pickCheckedByUserId: currentUserId,
              })
            }
          >
            Set Unchecked
          </Button>
        )}
        {!pickChecked && (
          <Button
            variant="danger"
            size="small"
            className="my-2"
            disabled={!picked}
            onClick={() =>
              picklistSupplierCatalogItemUpdateClicked({
                id,
                pickChecked: true,
                pickCheckedByUserId: currentUserId,
              })
            }
          >
            Set Checked
          </Button>
        )}
      </ButtonToolbar>
    );
  };

  const getPickInstructions = (row) => {
    const { catalogItemUnitOfMeasure, quantityUnitOfMeasure, quantityRemainder } = row;
    return `Pick ${quantityUnitOfMeasure} ${noCase(pluralize.plural(catalogItemUnitOfMeasure))} and ${quantityRemainder} units`;
  };

  const getUnitOfMeasurement = (row) => {
    const { catalogItemUnitOfMeasure, catalogItemQuantityPerUnitOfMeasure } = row;
    return `${catalogItemQuantityPerUnitOfMeasure}/${noCase(catalogItemUnitOfMeasure)}`;
  };

  const parentColumns = [
    {
      header: 'Model/Part',
      accessorKey: 'modelPartNumber',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Total Qty',
      accessorKey: 'quantity',
      enableColumnFilter: false,
    },
    {
      header: 'UoM',
      accessorKey: 'unitOfMeasurement',
      accessorFn: (row) => getUnitOfMeasurement(row),
      enableColumnFilter: false,
    },
    {
      header: 'Pick Instructions',
      accessorKey: 'pickInstructions',
      accessorFn: (row) => getPickInstructions(row),
      enableColumnFilter: false,
    },
  ];

  return (
    <ReactTable
      rootName={tableStateKey}
      parentColumns={parentColumns}
      data={picklistSupplierCatalogItems}
      doEdit={false}
      doShow={false}
      doDelete={false}
      actionCell={renderActionsCell}
      hideResetTable
    />
  );
};

export default PicklistSupplierCatalogItemList;
