import { useMemo, useCallback } from 'react';
import { Col, Row, Card, Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { validate as uuidValidate } from 'uuid';
import {
  RefreshCw as RefreshCwIcon,
  Table as TableIcon,
  Link as LinkIcon,
} from 'react-feather';

import get from 'lodash.get';

import LinkContainer from '../components/link_container';
import {
  renderOverlay,
  renderOffline,
  renderError,
  renderDateString,
} from '../components/render_helpers';
import ReactTable from '../components/react_table/react_table';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import {
  consignmentItemListPageQuery,
  consignmentItemDelete as consignmentItemDeleteMutation,
} from '../graphql/consignment_item_queries';
import { settingsSet, settingsReset } from '../store/settings_slice';
import * as updateFunctions from '../update_functions';

const ConsignmentItemList = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const [consignmentItemDelete] = useMutation(consignmentItemDeleteMutation);

  const tableStateKey = 'consignmentItem';

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
    client: pageClient,
  } = useQuery(consignmentItemListPageQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ consignmentItemList }) => {
      // write an individual query for every consignmentItem in the list
      updateFunctions.consignmentItemList(pageClient, consignmentItemList);
    },
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const tableResetClicked = useCallback(() => {
    dispatch(settingsReset(['tableState', tableStateKey]));
  }, [tableStateKey, dispatch]);

  const consignmentItemDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const consignmentItemId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: consignmentItemId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ConsignmentItemType',
        recordId: consignmentItemId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.consignmentItemDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'consignmentItemDelete',
      mutationData
    );
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await consignmentItemDelete(mutationData);
        toastSuccess('Consignment Item delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Consignment Item delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      }
    } else {
      consignmentItemDelete(mutationData);
      toastWarning(`Consignment Item delete ok locally. Go online to make permanent`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
  };

  const getConsignmentName = (row) => {
    const {
      consignment: {
        supplier: { name: supplierName },
        name,
      },
    } = row;
    return [supplierName, name].join('-');
  };

  const getDeliverySiteLocationName = (row) => {
    const deliverySiteLocationName = get(row, 'siteLocation.name', 'Unknown');
    return deliverySiteLocationName;
  };

  const getCurrentSiteLocationName = (row) => {
    const currentSiteLocationName = get(row, 'receipt.siteLocation.name', 'Unknown');
    return currentSiteLocationName;
  };

  const sortIsoString = (rowA, rowB) => {
    const isoStringA = get(rowA, ['original', 'receipt', 'createdAt']);
    const isoStringB = get(rowB, ['original', 'receipt', 'createdAt']);
    if (!isoStringA || !isoStringB) {
      if (isoStringA && !isoStringB) {
        return 1;
      }
      if (!isoStringA && isoStringB) {
        return -1;
      }
      return 0;
    }
    // eslint-disable-next-line no-nested-ternary
    return isoStringA < isoStringB ? -1 : isoStringA > isoStringB ? 1 : 0;
  };

  const getDeliveredAt = (row) => {
    const { receipt } = row;
    const { createdAt: deliveredAt } = receipt || {};
    return renderDateString(deliveredAt);
  };

  const renderEditReceipt = ({ row }) => {
    const receiptId = get(row, ['original', 'receipt', 'id']);
    if (receiptId) {
      return (
        <a target="_blank" href={`/receipts/${receiptId}/edit`} rel="noreferrer">
          <LinkIcon />
        </a>
      );
    }
    return undefined;
  };

  const parentColumns = [
    {
      header: 'Consignment',
      id: 'consignment',
      accessorFn: (row) => getConsignmentName(row),
      filterType: 'dropdown',
    },
    {
      header: 'Consignment Item Ref',
      accessorKey: 'consignmentItemReference',
      enableColumnFilter: true,
      filterFn: 'includesString',
      sortingFn: 'alphanumeric',
    },
    {
      header: 'Delivery Site Location',
      id: 'deliverySiteLocation',
      accessorFn: (row) => getDeliverySiteLocationName(row),
      filterType: 'dropdown',
    },
    {
      header: 'Current Site Location',
      id: 'currentSiteLocation',
      accessorFn: (row) => getCurrentSiteLocationName(row),
      filterType: 'dropdown',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      filterType: 'dropdown',
    },
    {
      header: 'Item Type',
      accessorKey: 'consignmentItemType',
      filterType: 'dropdown',
    },
    {
      header: 'Qty',
      accessorKey: 'quantity',
      enableColumnFilter: false,
    },
    {
      header: 'Kg',
      accessorKey: 'weightKg',
      enableColumnFilter: false,
    },
    {
      header: 'Delivered At',
      id: 'deliveredAt',
      enableColumnFilter: false,
      accessorFn: (row) => getDeliveredAt(row),
      sortingFn: sortIsoString,
    },
    {
      header: 'Edit Receipt',
      accessorKey: 'editReceipt',
      enableColumnFilter: false,
      cell: renderEditReceipt,
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <h1 className="h3 mb-3">Consignment Items</h1>
        </Col>
        <Col className="noprint" sm="auto">
          <ButtonToolbar>
            <ButtonGroup className="me-2">
              <LinkContainer to="/consignment_items/new">
                <Button variant="primary">New Consignment Item</Button>
              </LinkContainer>
            </ButtonGroup>
            <ButtonGroup className="me-0">
              <Button title="Reset table filters and sorting" onClick={tableResetClicked}>
                <TableIcon />
              </Button>
              <Button
                title="Refresh data"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                <RefreshCwIcon />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <ReactTable
                rootName={tableStateKey}
                parentColumns={parentColumns}
                data={pageData.consignmentItemList}
                doShow={false}
                doDelete={currentUser?.perms?.administrator}
                handleDelete={consignmentItemDeleteClicked}
                hideResetTable
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ConsignmentItemList;
