import { useMemo, useRef, useCallback } from 'react';
import { Col, Row, Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import {
  Printer as PrinterIcon,
  RefreshCw as RefreshCwIcon,
  Table as TableIcon,
} from 'react-feather';

import get from 'lodash.get';

import {
  renderOverlay,
  renderOffline,
  renderError,
} from '../../components/render_helpers';
import ReactTable from '../../components/react_table/react_table';
import { consignmentReportPageQuery } from '../../graphql/report_queries';
import { settingsReset } from '../../store/settings_slice';

const ConsignmentItemByDescriptionSummaryReport = () => {
  const dispatch = useDispatch();
  const printRef = useRef();
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);

  const tableStateKey = 'consignmentItemByDescriptionSummaryReport';

  const handlePrint = useReactToPrint({
    contentRef: printRef,
  });

  const totalFormatter = new Intl.NumberFormat('en-NZ', {
    style: 'decimal',
  });

  const percentageFormatter = new Intl.NumberFormat('en-NZ', {
    style: 'decimal',
    minimumFractionDigits: 2,
  });

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(consignmentReportPageQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const reportData = useMemo(
    () =>
      get(pageData, ['consignmentItemByDescriptionSummaryReport', 'report', 'data'], []),
    [pageData]
  );

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const tableResetClicked = useCallback(() => {
    dispatch(settingsReset(['tableState', tableStateKey]));
  }, [tableStateKey, dispatch]);

  const renderTotalCell = (cell) => <span>{totalFormatter.format(cell.getValue())}</span>;

  const renderPercentageCell = (cell) => (
    <span>{percentageFormatter.format(cell.getValue())}</span>
  );

  const parentColumns = [
    {
      header: 'SKU/Description',
      accessorKey: 'description',
      enableColumnFilter: true,
    },
    {
      header: 'Supplier',
      accessorKey: 'supplierName',
      filterType: 'dropdown',
    },
    {
      header: 'Quantity',
      accessorKey: 'descriptionTotal',
      enableColumnFilter: false,
      sortingFn: 'alphanumeric',
      headerClassName: 'text-end',
      cellClassName: 'text-end',
      cell: renderTotalCell,
    },
    {
      header: 'Receipted Quantity',
      accessorKey: 'descriptionReceiptedTotal',
      enableColumnFilter: false,
      sortingFn: 'alphanumeric',
      headerClassName: 'text-end',
      cellClassName: 'text-end',
      cell: renderTotalCell,
    },
    {
      header: 'Receipted %',
      accessorKey: 'descriptionReceiptedPercentage',
      enableColumnFilter: false,
      sortingFn: 'alphanumeric',
      headerClassName: 'text-end',
      cellClassName: 'text-end',
      cell: renderPercentageCell,
    },
    {
      header: 'Unreceipted Quantity',
      accessorKey: 'descriptionUnreceiptedTotal',
      enableColumnFilter: false,
      sortingFn: 'alphanumeric',
      headerClassName: 'text-end',
      cellClassName: 'text-end',
      cell: renderTotalCell,
    },
    {
      header: 'Unreceipted %',
      accessorKey: 'descriptionUnreceiptedPercentage',
      enableColumnFilter: false,
      sortingFn: 'alphanumeric',
      headerClassName: 'text-end',
      cellClassName: 'text-end',
      cell: renderPercentageCell,
    },
    {
      header: 'Undamaged Quantity',
      accessorKey: 'descriptionUndamagedTotal',
      enableColumnFilter: false,
      sortingFn: 'alphanumeric',
      headerClassName: 'text-end',
      cellClassName: 'text-end',
      cell: renderTotalCell,
    },
    {
      header: 'Undamaged %',
      accessorKey: 'descriptionUndamagedPercentage',
      enableColumnFilter: false,
      sortingFn: 'alphanumeric',
      headerClassName: 'text-end',
      cellClassName: 'text-end',
      cell: renderPercentageCell,
    },
    {
      header: 'Damaged Quantity',
      accessorKey: 'descriptionDamagedTotal',
      enableColumnFilter: false,
      sortingFn: 'alphanumeric',
      headerClassName: 'text-end',
      cellClassName: 'text-end',
      cell: renderTotalCell,
    },
    {
      header: 'Damaged %',
      accessorKey: 'descriptionDamagedPercentage',
      enableColumnFilter: false,
      sortingFn: 'alphanumeric',
      headerClassName: 'text-end',
      cellClassName: 'text-end',
      cell: renderPercentageCell,
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <h1 className="h3 mb-3">Consignment Item By SKU Summary Report</h1>
        </Col>
        <Col className="noprint" sm="auto">
          <ButtonToolbar>
            <ButtonGroup className="me-2">
              <Button title="Print report or save as PDF" onClick={handlePrint}>
                <PrinterIcon />
              </Button>
            </ButtonGroup>
            <ButtonGroup className="me-0">
              <Button title="Reset table filters and sorting" onClick={tableResetClicked}>
                <TableIcon />
              </Button>
              <Button
                title="Refresh data"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                <RefreshCwIcon />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactTable
            rootName={tableStateKey}
            parentColumns={parentColumns}
            data={reportData}
            doShow={false}
            doDelete={false}
            hideActions
            hideResetTable
          />
        </Col>
      </Row>
    </>
  );

  return (
    <div ref={printRef}>
      <style>{`@media print {.noprint{display: none;}}`}</style>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ConsignmentItemByDescriptionSummaryReport;
